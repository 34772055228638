<template>
  <div class="container">
    <TitleBarBase />
    <el-form ref="form" :model="sysform" :rules="rules" label-width="auto">
      <el-row :gutter="30">
        <el-col :span="6">
          <el-form-item prop="logoImg">
            <div>LOGO</div>
            <upload-place v-model="sysform.logoImg" :styleObject="{ width: '120px', height: '120px' }" />
            <small style="font-size: 13px;color: #999;">建议尺寸 150*150</small>
          </el-form-item>
          <el-form-item prop="systemName" label="系统名称">
            <el-input v-model="sysform.systemName" placeholder="输入系统的名称"></el-input>
          </el-form-item>
          <el-form-item prop="copyright" label="版权文字">
            <el-input v-model="sysform.copyright" placeholder="请输入版权描述"> </el-input>
          </el-form-item>
          <el-form-item prop="companyRecord" label="备案说明">
            <el-input v-model="sysform.companyRecord" placeholder="请输入备案信息"> </el-input>
          </el-form-item>
          <el-form-item prop="balanceWithdrawalsNum" label="余额提现">
            <el-tooltip class="item" effect="dark" content="每月可提现几次,0代表不限制" placement="top">
              <el-input v-model="sysform.balanceWithdrawalsNum" placeholder="每月可提现几次,0代表不限制" v-Int>
                <i slot="suffix">次</i>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item prop="rewardWithdrawalsNum" label="奖金提现">
            <el-tooltip class="item" effect="dark" content="每月可提现几次,0代表不限制" placement="top">
              <el-input v-model="sysform.rewardWithdrawalsNum" placeholder="每月可提现几次,0代表不限制" v-Int>
                <i slot="suffix">次</i>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item prop="vendingmWithdrawalsNum" label="盒子提现">
            <el-tooltip class="item" effect="dark" content="每月可提现几次,0代表不限制" placement="top">
              <el-input v-model="sysform.vendingmWithdrawalsNum" placeholder="每月可提现几次,0代表不限制" v-Int>
                <i slot="suffix">次</i>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item prop="autoReceipt" label="自动收货天数">
            <el-tooltip class="item" effect="dark" content="自动收货天数" placement="top">
              <el-input v-model="sysform.autoReceipt" placeholder="自动收货天数" v-Int>
                <i slot="suffix">天</i>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSave">保存</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="banner">
            <div>首页banner</div>
            <upload-place v-model="sysform.banner" :styleObject="{ width: '250px', height: '120px' }" />
            <small style="font-size: 13px;color: #999;">建议尺寸 750*360</small>
          </el-form-item>
          <el-form-item prop="becomeGoldenNeedMoney" label="金牌门槛费用">
            <el-input v-model="sysform.becomeGoldenNeedMoney" placeholder="请输入数字" v-numbarFloat>
              <i slot="suffix">元</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="firstLevelRewardScale" label="一级金牌分红">
            <el-input v-model="sysform.firstLevelRewardScale" placeholder="请输入数字" v-numbarFloat>
              <i slot="suffix">%</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="secondLevelRewardScale" label="二级金牌分红">
            <el-input v-model="sysform.secondLevelRewardScale" placeholder="请输入数字" v-numbarFloat>
              <i slot="suffix">%</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="officialReferRewardScale" label="官方推广分红">
            <el-input v-model="sysform.officialReferRewardScale" placeholder="请输入数字" v-numbarFloat>
              <i slot="suffix">%</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="vendingmRewardScale" label="智能盒子分红">
            <el-input v-model="sysform.vendingmRewardScale" placeholder="请输入数字" v-numbarFloat>
              <i slot="suffix">%</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="vendingmFeeScale" label="盒子手续费用">
            <el-input v-model="sysform.vendingmFeeScale" placeholder="请输入数字" v-numbarFloat>
              <i slot="suffix">%</i>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="shareImg">
            <div>首页分享图</div>
            <upload-place v-model="sysform.shareImg" :styleObject="{ width: '120px', height: '120px' }" />
            <small style="font-size: 13px;color: #999;">建议尺寸 500*500</small>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import TitleBarBase from '@/components/TitleBar/TitleBarBase.vue'
import { sysInfoGet, sysInfoSet } from '@/api/systemController'
import uploadPlace from '@/components/upload/uploadPlace.vue'
export default {
  components: {
    uploadPlace,
    TitleBarBase
  },
  data() {
    return {
      rules: {
        // logoImg: [{ type: 'string', message: '请填写系统名称', trigger: 'blur' }],
        // name: [{ type: 'string', message: '请填写系统名称', trigger: 'blur' }],
        // copyright: [{ type: 'string', message: '请填写版权', trigger: 'blur' }],
        // record: [{ type: 'string', message: '请填写备案说明', trigger: 'blur' }],
      },
      sysform: {
        balanceWithdrawalsNum: 0,
        shareImg: '',
        banner: '',
        companyRecord: '',
        copyright: '',
        createTime: '',
        firstLevelRewardScale: 0,
        lastUpdateTime: '',
        logoImg: '',
        officialReferRewardScale: 0,
        rewardWithdrawalsNum: 0,
        secondLevelRewardScale: 0,
        setsId: '',
        smsVal: '',
        systemName: '',
        vendingmFeeScale: 0,
        vendingmRewardScale: 0,
        vendingmWithdrawalsNum: 0,
        passWord: '',
        becomeGoldenNeedMoney: 0,
        autoReceipt: ''
      }
    }
  },
  mounted() {
    this.getSystemInfo()
  },
  methods: {
    async handleSave() {
      const promptResult = await this.$prompt('请输入密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /.*[^\s]/,
        inputErrorMessage: '不可以为空'
      }).catch(err => err)
      if (promptResult.action != 'confirm') return
      this.sysform.passWord = promptResult.value //203897
      let [err, res] = await sysInfoSet(this.sysform)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '保存失败')
      }
      console.log(res)
      this.$message.success(res.msg || '保存成功')
    },
    async getSystemInfo() {
      let [err, res] = await sysInfoGet()
      if (err || !res.data) {
        console.log(err)
        return this.$message.error(err.msg || '获取失败')
      }
      console.log(res)
      // this.$message.success(res.message || '获取成功')
      this.sysform = res.data
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  width: 100%;
}
</style>
